<template>
    <div class="my-16">
        <v-row v-if="isDetails.is_commission.length != 0">
            <v-col :cols="3">
                <h4>Status</h4>
            </v-col>
            <v-col :cols="3">
                <h4>Created At</h4>
            </v-col>
            <v-col :cols="3">
                <h4>Commission Rate</h4>
            </v-col>
        </v-row>
        <v-row v-for="(item, index) in isDetails.is_commission" :key="index">
            <v-col :cols="3">
                <v-text-field
                    :value="checkType(item.type)"
                    label="Status"
                    hide-details="auto"
                    outlined
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="3">
                <v-text-field
                    v-model="item.created_at"
                    label="Created At"
                    hide-details="auto"
                    outlined
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="3">
                <v-text-field
                    v-model="item.comm_rate"
                    label="Commission Rate"
                    hide-details="auto"
                    outlined
                    type="number"
                    hide-spin-buttons
                    dense
                    :readonly="denyView"
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="1" v-if="currUser.user_role === 'encoder' && !denyView">
                <v-btn
                    text
                    color="error"
                    @click="removeCommission(item, index)"
                    >
                    <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col v-if="!disable && isDetails.is_commission.length == 0">
                <v-btn
                    class="mx-5"
                    color="primary"
                    style="float:right;"
                    @click="dialog = true"
                    >
                    <b>Add Commission +</b>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" persistent max-width="800">
            <v-card class="pt-5">
                <v-card-text class="my-5">
                     <span class="text-h5" id="dialog_title">Add Commission</span>
                </v-card-text>
                                
                <v-row class="mx-5 mb-3">
                    <v-col :cols="6">
                        <v-text-field
                            v-model="newCommission.comm_rate"
                            label="Commission Rate"
                            hide-details="auto"
                            outlined
                            dense
                            type="number"
                            hide-spin-buttons
                            >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-card-actions class="pb-5 py-5">
                    <v-btn
                        class="mx-3"
                        color="primary"
                        style="float:right;"
                        outlined
                        @click="dialog = false, clearNewCommission()"
                        >
                        <b>Cancel</b>
                    </v-btn>
                        <v-spacer></v-spacer>
                    <v-btn
                        v-if="newCommission.comm_rate"
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        @click="addCommission(), dialog = false"
                        >
                        <b>Add</b>
                    </v-btn>
                    <v-btn
                        v-else
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        disabled
                        >
                        <b>Add</b>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
export default{
    computed: {
        ...mapGetters({
            currUser: 'auth/currUser',
            isDetails: 'finance/isDetails',
            disable: 'finance/disable'
        })
    },
    data: () => ({
        dialog: false,
        denyView: false,
        newCommission: {
            id : null,
            bp_idno : '',
            type: 'create',
            comm_rate : '',
        },
    }),
    created (){
        if (this.currUser.user_role === 'encoder' && this.isDetails.finance_status == 'New'){
            this.denyView = false
        } else {
            this.denyView = true
        }
    },  
    methods: {
        checkType(type){
            if (type == 'update') {
                return 'Existing'
            } else if (type == 'create'){
                return 'New'
            } else if (type == 'delete'){
                return 'Delete'
            }
        },
        addCommission(){
            this.newCommission.bp_idno =  this.isDetails.bp_idno,
            this.isDetails.is_commission.push(this.newCommission)
            this.clearNewCommission()
        },
        clearNewCommission(){
            this.newCommission = {
                id : null,
                bp_idno : '',
                type: 'create',
                comm_rate: ''
            }
        },
        async removeCommission(e , index){
            if (e.id == null){
                this.isDetails.is_commission.splice(index, 1)
            } else if (this.isDetails.is_commission[index].type == 'delete'){
                this.isDetails.is_commission[index].type = 'update'
            } else {
                this.isDetails.is_commission[index].type = 'delete'
            }
        },
        formatDate (date) {
            return format(parseISO(date), 'yyyy-MM-dd')
        },

    }
}
</script>
