<template>
    <div class="mt-3">
        <v-row class="mb-5" v-if="item.commissionCreate.length != 0 || item.commissionUpdate.length != 0 || item.commissionDelete.length != 0">
            <h3 style="color: blue">Commission Audit Trail</h3>
        </v-row>
        <v-row v-if="item.commissionCreate.length != 0" class="mb-3">
            <h4>New Commission</h4>
        </v-row>
        <v-row v-for="(item, index) in item.commissionCreate" :key="index" class="mr-5">
                <v-col :cols="3">
                    <v-text-field
                        label="Status"
                        :value="checkType(item.type)"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="BP ID Number"
                        v-model="item.bp_idno"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="SP Number"
                        v-model="item.sales_prospects_id"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="Created By"
                        v-model="item.created_by"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="Approve Date"
                        :value="formatDate(item.approved_date)"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="1" class="col-xs-1 text-center">
                    <v-btn
                        color="primary"
                        small
                        @click="item.showDetails = !item.showDetails"
                        >
                        <b v-if="item.showDetails">Hide Details <v-icon small>mdi-arrow-up-bold</v-icon></b>
                        <b v-else>View Details <v-icon small>mdi-arrow-down-bold</v-icon></b>
                    </v-btn>
                </v-col>
                
                <v-col>
                    <div v-if="item.showDetails">
                        <h5 class="mb-5">Commission Details</h5>
                        <v-row class="mx-1 mb-1 pr-15">
                            <v-col :cols="6">
                                <v-text-field
                                    dense
                                    label="Commission Rate"
                                    v-model="item.new_value.comm_rate"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
        </v-row>

        <hr v-if="item.commissionCreate.length != 0">

        <v-row v-if="item.commissionUpdate.length != 0" class="mb-3 mt-6">
            <h4>Update Commission Details</h4>
        </v-row>

        <v-row v-for="(item, index) in item.commissionUpdate" :key="index" class="mr-5">
            <v-col :cols="3">
                <v-text-field
                    label="Status"
                    :value="checkType(item.type)"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-text-field
                    label="BP ID Number"
                    v-model="item.bp_idno"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-text-field
                    label="SP Number"
                    v-model="item.sales_prospects_id"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-text-field
                    label="Created By"
                    v-model="item.created_by"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-text-field
                    label="Approve Date"
                    :value="formatDate(item.approved_date)"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="1" class="col-xs-1 text-center">
                <v-btn
                    color="primary"
                    @click="item.showDetails = !item.showDetails"
                    small
                    >
                    <b v-if="item.showDetails">Hide Details <v-icon small>mdi-arrow-up-bold</v-icon></b>
                    <b v-else>View Details <v-icon small>mdi-arrow-down-bold</v-icon></b>
                </v-btn>
            </v-col>
            
            <v-col>
                <div v-if="item.showDetails">
                    <h5 class="mb-5">New Commission Details</h5>
                    <v-row class="mx-1 mb-1 pr-15">
                        <v-col :cols="6">
                            <v-text-field
                                dense
                                label="Commission Rate"
                                v-model="item.new_value.comm_rate"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <h5 class="mb-5">Previous Commission Details</h5>
                    <v-row class="mx-1 mb-1 pr-15">
                        <v-col :cols="6">
                            <v-text-field
                                dense
                                label="Commission Rate"
                                v-model="item.prev_value.comm_rate"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <hr v-if="item.commissionUpdate.length != 0">

        <v-row v-if="item.commissionDelete.length != 0" class="mb-3 mt-10">
            <h4>Delete Commission</h4>
        </v-row>
        <v-row v-for="(item, index) in item.commissionDelete" :key="index" class="mr-5">
                <v-col :cols="3">
                    <v-text-field
                        label="Status"
                        :value="checkType(item.type)"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="BP ID Number"
                        v-model="item.bp_idno"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="SP Number"
                        v-model="item.sales_prospects_id"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="Created By"
                        v-model="item.created_by"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="Approve Date"
                        :value="formatDate(item.approved_date)"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="1" class="col-xs-1 text-center">
                    <v-btn
                        color="primary"
                        small
                        @click="item.showDetails = !item.showDetails"
                        >
                        <b v-if="item.showDetails">Hide Details <v-icon small>mdi-arrow-up-bold</v-icon></b>
                        <b v-else>View Details <v-icon small>mdi-arrow-down-bold</v-icon></b>
                    </v-btn>
                </v-col>
                
                <v-col>
                    <div v-if="item.showDetails">
                        <h5 class="mb-5">Commission Details</h5>
                        <v-row class="mx-1 mb-1 pr-15">
                            <v-col :cols="6">
                                <v-text-field
                                    dense
                                    label="Commission Rate"
                                    v-model="item.prev_value.comm_rate"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'

export default{
    props: ["item"],
    data: () => ({
        archiveDialog: false,
        showDetails: false,
    }),
    computed: {
        ...mapGetters({
            isDetails: 'finance/isDetails'
        })
    },
    mounted () {
        this.assignData()
	},
    methods: {
        checkName(item){
            if (item.prev_value){
                if (item.prev_value.acct_name == null){
                    return item.new_value.acct_name
                } else {
                    return item.prev_value.acct_name
                }
            } else {
                return item.new_value.acct_name
            }
        },
        assignData() {
            console.log(this.item)
        },
        formatDate (date) {
            if (date != null){
                return format(parseISO(date), 'yyyy-MM-dd')
            }
        },
        checkType(type){
            if (type == 'create'){
                return 'New Commission'
            } else if (type == 'update'){
                return 'Update Commission Details'
            } else if (type == 'delete'){
                return 'Delete Commission'
            }
        },
    }
}
</script>
