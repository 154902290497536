<template>
    <div class="my-16">
        <v-row v-if="isDetails.is_email.length != 0">
            <v-col :cols="12">
                <h4>Email Address</h4>
            </v-col>
        </v-row>
        <v-row v-for="(item, index) in isDetails.is_email" :key="index">
            <v-col :cols="11">
                <v-text-field
                    v-model="item.email_add"
                    label="Email"
                    hide-details="auto"
                    outlined
                    dense
                    :readonly="disable"
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="1" v-if="!disable">
                <v-btn
                    text
                    color="error"
                    @click="removeEmail(item, index)"
                    >
                    <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col v-if="!disable">
                <v-btn
                    class="mx-5"
                    color="primary"
                    style="float:right;"
                    @click="dialog = true"
                    >
                    <b>Add Email +</b>
                </v-btn>
                <v-btn
                    v-if="isDetails.is_email.length != 0"
                    color="success"
                    style="float:right;"
                    @click="updateEmail"
                    >
                    <b>Save Email</b>
                </v-btn>
                <v-btn
                    v-else
                    color="success"
                    style="float:right;"
                    disabled
                    >
                    <b>Save Email</b>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" persistent max-width="800">
            <v-card class="pt-5">
                <v-card-text class="my-5">
                     <span class="text-h5" id="dialog_title">Add New Email</span>
                </v-card-text>
                                
                <v-row class="mx-5">
                    <v-col :cols="12">
                        <v-text-field
                            v-model="newEmail.email_add"
                            label="Email"
                            hide-details="auto"
                            outlined
                            dense
                            >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-card-actions class="pb-5 py-5">
                    <v-btn
                        class="mx-3"
                        color="primary"
                        style="float:right;"
                        outlined
                        @click="dialog = false, clearNewEmail()"
                        >
                        <b>Cancel</b>
                    </v-btn>
                        <v-spacer></v-spacer>
                    <v-btn
                        v-if="newEmail.email_add"
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        @click="addEmail(), dialog = false"
                        >
                        <b>Add</b>
                    </v-btn>
                    <v-btn
                        v-else
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        disabled
                        >
                        <b>Add</b>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
export default{
    computed: {
        ...mapGetters({
            currUser: 'auth/currUser',
            isDetails: 'finance/isDetails',
            disable: 'finance/disable'
        })
    },
    data: () => ({
        dialog: false,
        newEmail: {
            id : null,
            bp_idno : '',
            sales_prospects_id : '',
            email_add: ''
        },
    }),
    methods: {
        async updateEmail(){
            const payload = {
                id : this.isDetails.bp_idno,
                finalPayload : {
                    is_email : this.isDetails.is_email
                }
            }
            const res = await this.$store.dispatch('finance/doUploadEmail', ({payload}))
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Updated Successfully!','messagetype':'success'});
                this.assignData()
            } else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Something went wrong!','messagetype':'error'});
            }
        },
        addEmail(){
            this.newEmail.sales_prospects_id = this.isDetails.id,
            this.newEmail.bp_idno =  this.isDetails.bp_idno,
            this.isDetails.is_email.push(this.newEmail)
            this.clearNewEmail()
        },
        clearNewEmail(){
            this.newEmail = {
                id : null,
                bp_idno : '',
                sales_prospects_id : '',
                email_add: ''
            }
        },
        async removeEmail(e , index){
            if (e.id == null){
                this.isDetails.is_email.splice(index, 1)
            } else {
                const res = await this.$store.dispatch('finance/doDeleteEmail', e.id)
                if (res.status == 200){
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Deleted Successfully!','messagetype':'success'});
                    this.assignData()
                } else {
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Something went wrong!','messagetype':'error'});
                }
            }
        },
        async assignData(){
            const res = await this.$store.dispatch('finance/doGetIsDetails', this.$route.params.id)

            res.data.result.map( e => {

                if (e.sp_other_primary_details){
                    if (e.sp_other_primary_details.business_type_of_sub_agents){
                        e.sp_other_primary_details.business_type_of_sub_agents = JSON.parse(e.sp_other_primary_details.business_type_of_sub_agents)
                    }
                }

                if (e.sp_location.length != 0){
                    e.sp_location[0].mother_of_contract = JSON.parse(e.sp_location[0].mother_of_contract)
                    e.sp_location[0].moa_nonex = JSON.parse(e.sp_location[0].moa_nonex)
                    e.sp_location[0].moa_avp = JSON.parse(e.sp_location[0].moa_avp)
                }
                
                e.bank_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.commission_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.charges_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                // Commission Details
                if (e.is_commission.length != 0){
                    
                    e.is_commission.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })

                    let latestDate = e.is_commission.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });

                    e.is_commission = [latestDate]
                }

                // Charges Details

                if (e.is_charges.length != 0){
                    let newArr = []
                    e.is_charges.map( e=> {
                        if (e.tran_type == 'COM'){
                            newArr.push(e)
                        }
                    })
                    newArr.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })
                    let latestDate = newArr.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });
                    e.is_charges = [latestDate]
                }


                if (e.is_def_bank_accounts){
                    e.is_def_bank_accounts.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_commission){
                    e.is_commission.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_charges){
                    e.is_charges.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }

                        if (e.charge_rate){
                            e.charge_rate = parseInt(e.charge_rate)
                        }
                    })
                }

                // Branch Details
                if (e.sp_branch.data.length != 0){
                    e.sp_branch.data.map( e => {
                        if (e.pc_equip == 'Y' || e.pc_equip == 'N'){
                            e.pc_equip = 0
                        } else if (typeof e.pc_equip == 'string'){
                            parseInt(e.pc_equip)
                        }
                    })
                }

            })
            this.$store.commit('finance/SET_IS_DETAILS', res.data.result[0])
        },
        formatDate (date) {
            return format(parseISO(date), 'yyyy-MM-dd')
        },
    }
}
</script>
