<template>
    <div class="my-16">
        <v-row class="mb-5" v-if="isDetails.is_def_bank_accounts.length != 0">
            <v-col :cols="1">
                <h4>Status</h4>
            </v-col>
            <v-col :cols="2">
                <h4>Account Type</h4>
            </v-col>
            <v-col :cols="1">
                <h4>Account Currency</h4>
            </v-col >
            <v-col :cols="2">
                <h4>Bank Code</h4>
            </v-col>
            <v-col :cols="2">
                <h4>Account No</h4>
            </v-col>
            <v-col :cols="2">
                <h4>Account Name</h4>
            </v-col>
            <v-col :cols="2">
                <h4>Signatory</h4>
            </v-col>
        </v-row>
        <v-row v-for="(item, index) in isDetails.is_def_bank_accounts" :key="index">
            <v-col :cols="1">
                <v-text-field
                    :value="checkType(item.type)"
                    label="Status"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-autocomplete
                    v-model="item.acct_type"
                    dense
                    solo
                    :items="accountType"
                    placeholder="Type"
                    :readonly="denyView"
                >
                </v-autocomplete>
            </v-col>
            <v-col :cols="1">
                <v-autocomplete
                    v-model="item.acct_cur"
                    dense
                    solo
                    :items="accountCurrency"
                    placeholder="Currency"
                    :readonly="denyView"
                >
                </v-autocomplete>
            </v-col >
            <v-col :cols="2">
                <v-autocomplete
                    v-model="item.bank_code"
                    dense
                    solo
                    :items="bankCode"
                    item-text="code"
                    placeholder="Bank"
                    :readonly="denyView"
                >
                </v-autocomplete>
                 <h5>{{ getBankName(item.bank_code) }}</h5>
            </v-col>
            <v-col :cols="2">
                <v-text-field
                    v-model="item.acct_no"
                    label="Account"
                    hide-details="auto"
                    outlined
                    dense
                    :readonly="denyView"
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-text-field
                    v-model="item.acct_name"
                    label="Name"
                    hide-details="auto"
                    outlined
                    dense
                    :readonly="denyView"
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="1">
                <v-text-field
                    v-model="item.signatory"
                    label="Signatory"
                    hide-details="auto"
                    outlined
                    dense
                    :readonly="denyView"
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="1" v-if="!denyView">
                <v-btn
                    text
                    color="error"
                    @click="deleteItem(item, index)"
                    >
                    <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        
        <v-row v-if="!denyView">
            <v-col>
                <v-btn
                    color="primary"
                    style="float:right;"
                    @click="dialog = true"
                    >
                    <b>Add Bank Account</b>
                </v-btn>
                <v-btn
                    class="mx-3"
                    v-if="isDetails.is_def_bank_accounts.length != 0"
                    color="success"
                    style="float:right;"
                    @click="updateBankDetails"
                    >
                    <b>Submit For Approval</b>
                </v-btn>
                <v-btn
                    class="mx-3"
                    v-else
                    color="success"
                    style="float:right;"
                    disabled
                    >
                    <b>Submit For Approval</b>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" persistent max-width="1300">
            <v-card class="pt-5">
                <v-card-text class="pt-5 my-5">
                     <span class="text-h5" id="dialog_title">Add Bank Account</span>
                </v-card-text>
                                
                <v-row class="mx-5">
                    <v-col :cols="2">
                        <v-autocomplete
                            v-model="newBankDetails.acct_type"
                            dense
                            solo
                            :items="accountType"
                            placeholder="Type"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col :cols="2">
                        <v-autocomplete
                            v-model="newBankDetails.acct_cur"
                            dense
                            solo
                            :items="accountCurrency"
                            placeholder="Currency"
                        >
                        </v-autocomplete>
                    </v-col >
                    <v-col :cols="2">
                        <v-autocomplete
                            v-model="newBankDetails.bank_code"
                            dense
                            solo
                            :items="bankCode"
                            item-text="code"
                            placeholder="Bank"
                        >
                        </v-autocomplete>
                        <h5>{{ getBankName(newBankDetails.bank_code) }}</h5>
                    </v-col>
                    <v-col :cols="2">
                        <v-text-field
                            v-model="newBankDetails.acct_no"
                            label="Account"
                            hide-details="auto"
                            outlined
                            dense
                            >
                        </v-text-field>
                    </v-col>
                    <v-col :cols="2">
                        <v-text-field
                            v-model="newBankDetails.acct_name"
                            label="Name"
                            hide-details="auto"
                            outlined
                            dense
                            >
                        </v-text-field>
                    </v-col>
                    <v-col :cols="2">
                        <v-text-field
                            v-model="newBankDetails.signatory"
                            label="Signatory"
                            hide-details="auto"
                            outlined
                            dense
                            >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-card-actions class="pb-5">
                    <v-btn
                        class="mx-3"
                        color="primary"
                        style="float:right;"
                        outlined
                        @click="dialog = false, clearNewBankDetails()"
                        >
                        <b>Cancel</b>
                    </v-btn>
                        <v-spacer></v-spacer>
                    <v-btn
                        v-if="newBankDetails.acct_type && newBankDetails.acct_cur && newBankDetails.bank_code && newBankDetails.acct_no && newBankDetails.acct_name"
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        @click="addNewDetails(), dialog = false"
                        >
                        <b>Add</b>
                    </v-btn>
                    <v-btn
                        v-else
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        disabled
                        >
                        <b>Add</b>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'

export default{
    computed: {
        ...mapGetters({
            currUser: 'auth/currUser',
            isDetails: 'finance/isDetails'
        })
    },
    data: () => ({
        denyView: null,
        accountType: ['CA', 'CHK', 'SAV', 'SPE'],
        accountCurrency: ['PESO', 'DOLLAR'],
        bankCode :[
            { code: 'ING', name: 'ING BANK NV' },
            { code: 'ESB', name: 'EQUICOM SAVINGS BANK' },
            { code: 'MICB', name: 'MEGA INTERNATIONAL COMMERCIAL BANK' },
            { code: 'AUBP', name: 'ASIAN UNITED BANK PESO' },
            { code: 'AUBD', name: 'ASIAN UNITED BANK DOLLAR' },
            { code: 'MAYB', name: 'MAYBANK' },
            { code: 'BOA', name: 'BANK OF AMERICA' },
            { code: 'MBTC', name: 'METROPOLITAN BANK & TRUST CO.' },
            { code: 'ALLI', name: 'ALLIED BANK' },
            { code: 'UCPB', name: 'UNITED COCONUT PLANTERS BANK' },
            { code: 'FEBT', name: 'FAR EAST BANK AND TRUST COMPANY' },
            { code: 'URBN', name: 'URBAN BANK' },
            { code: 'LNBP', name: 'LAND BANK OF THE PHILIPPINES' },
            { code: 'PNBB', name: 'PHILIPPINE NATIONAL BANK' },
            { code: 'MTBP', name: 'METROBANK PESO' },
            { code: 'RCBC', name: 'RIZAL COMMERCIAL BANKING CORP.' },
            { code: 'CHIB', name: 'CHINABANK' },
            { code: 'CITI', name: 'CITIBANK' },
            { code: 'BPIS', name: 'BANK OF THE PHILIPPINE ISLAND' },
            { code: 'ALDB', name: 'ALLIED BANK' },
            { code: 'PCIB', name: 'PCI BANK / EQUITABLE BANK' },
            { code: 'BPIF', name: 'BPI FAMILY SAVINGS BANK' },
            { code: 'UNIB', name: 'UNIONBANK OF THE PHILIPPINES' },
            { code: 'EIB', name: 'EXPORT AND INDUSTRY BANK' },
            { code: 'BPI', name: 'BANK OF THE PHILIPPINE ISLANDS' },
            { code: 'CENT', name: 'CENTENNIAL BANK' },
            { code: 'BDO', name: 'BANCO DE ORO' },
            { code: 'CNT', name: 'CTBC BANK' },
            { code: 'XXXX', name: 'MY BANK' },
            { code: 'MERC', name: 'MERCHANTS BANK' },
            { code: 'ALID', name: 'ALLIED BANK DOLLAR' },
            { code: 'ALIP', name: 'ALLIED BANK PESO' },
            { code: 'MYBD', name: 'MAYBANK' },
            { code: 'EWB', name: 'EASTWEST BANK' },
            { code: 'BPID', name: 'BANK OF THE PHILIPPINE ISLAND' },
            { code: 'PDB', name: 'CHINA BANK SAVINGS INC.' },
            { code: 'PBBD', name: 'PHILIPPINE BUSINESS BANK DOLLAR' },
            { code: 'PBBP', name: 'PHILIPPINE BUSINESS BANK PESO' },
            { code: 'DBD', name: 'DEUTSCHE BANK DOLLAR' },
            { code: 'DBP', name: 'DEUTSCHE BANK PESO' },
            { code: 'SECD', name: 'SECURITY BANK & TRUST COMPANY' },
            { code: 'SECP', name: 'SECURITY BANK & TRUST COMPANY' },
            { code: 'PBB', name: 'PUBLIC BANK BERHAD' },
            { code: 'SBA', name: 'STERLING BANK' },
            { code: 'AB', name: 'ALLIED BANK' },
            { code: 'CSBP', name: 'CITY SAVINGS BANK PESO' },
            { code: 'CSBD', name: 'CITY SAVINGS BANK DOLLAR' },
            { code: 'SBTC', name: 'SECURITY BANK & TRUST COMPANY' },
            { code: 'DB', name: 'DEUTSCHE BANK' },
            { code: 'CITD', name: 'CITIBANK DOLLAR' },
            { code: 'CITP', name: 'CITIBANK PESO' },
            { code: 'SCBD', name: 'SECURITY BANK & TRUST COMPANY' },
            { code: 'SCBP', name: 'SECURITY BANK & TRUST COMPANY' },
            { code: 'BOCP', name: 'BANK OF COMMERCE' },
            { code: 'BOCD', name: 'BANK OF COMMERCE (US $)' },
            { code: 'JPMB', name: 'JPMORGAN CHASE BANK' },
            { code: 'MTBD', name: 'METROBANK DOLLAR' },
            { code: 'HSBD', name: 'HSBC DOLLAR' },
            { code: 'HSBP', name: 'HSBC PESO' },
            { code: 'SCB', name: 'STANDARD CHARTERED BANK' },
            { code: 'MSBP', name: 'MALAYAN BANK SAVINGS PESO' },
            { code: 'MSBD', name: 'MALAYAN BANK SAVINGS DOLLAR' },
            { code: 'BOC', name: 'BANK OF COMMERCE' },
            { code: 'PBBB', name: 'PHILIPPINE BUSINESS BANK' },
            { code: 'PVCD', name: 'PHIL VETERANS BANK' },
            { code: 'PCOM', name: 'PB COM' },
            { code: 'ROBB', name: 'ROBINSONSBANK' },
            { code: 'SBTD', name: 'SECURITY BANK & TRUST COMPANY' },
            { code: 'LBP2', name: 'LAND BANK OF THE PHILIPPINE (Formerly UCPB)' },
            { code: 'SMBC', name: 'SUMITOMO MITSUI BANKING CORPORATION' },
            { code: 'UOBL', name: 'UNITED OVERSEAS BANK LIMITED' },
            { code: 'MB', name: 'MIZUHO BANK' },
            { code: 'PSB', name: 'PHILIPPINE SAVINGS BANK' },
            { code: 'CBSI', name: 'CHINABANK SAVINGS INC' },
            { code: 'BANG', name: 'BANGKOK BANK PUBLIC COMPANY LIMITED'}
        ],
        dialog: false,
        newBankDetails:{
            id : null,
            sales_prospects_id: '',
            type: 'create',
            bp_idno: '',
            acct_type: '',
            acct_cur: '',
            bank_code: '',
            acct_no: '',
            acct_name: '',
            signatory: '',
        },
    }),
    created (){
        if (this.currUser.is_onboarding_committee == 1){
            this.denyView = false
        } else {
            this.denyView = true
        }
    },  
    methods: {
        getBankName(code) {
            const selectedBank = this.bankCode.find(bank => bank.code === code);
            return selectedBank ? selectedBank.name : '';
        },
        clearNewBankDetails(){
            this.newBankDetails = {
                id : null,
                type: 'create',
                sales_prospects_id: '',
                bp_idno: '',
                acct_type: '',
                acct_cur: '',
                bank_code: '',
                acct_no: '',
                acct_name: '',
                signatory: '',
            }
        },
        addNewDetails(){
            this.newBankDetails.sales_prospects_id = this.isDetails.id,
            this.newBankDetails.bp_idno =  this.isDetails.bp_idno,
            this.isDetails.is_def_bank_accounts.push(this.newBankDetails)
            this.clearNewBankDetails()
        },
        async updateBankDetails(){
            this.isDetails.is_def_bank_accounts.map( e => {
                console.log(e.type)
                if (e.id && e.type != 'delete'){
                    e.type = 'update'
                }
            })
            const payload = {
                id : this.$route.params.id,
                finalPayload: {
                    bank : this.isDetails.is_def_bank_accounts
                }
            }
            const res = await this.$store.dispatch('finance/doUpdateBankDetails', {payload})
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.data.result,'messagetype':'success'});
                this.assignData()
            } else if (res.status === 206 || res.code === 400 || res.code === 422 || res.code === 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            } else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
            }
        },
        async deleteItem(e , index){
            if (e.id == null){
                this.isDetails.is_def_bank_accounts.splice(index, 1)
            } else if (this.isDetails.is_def_bank_accounts[index].type == 'delete'){
                this.isDetails.is_def_bank_accounts[index].type = 'update'
            } else {
                this.isDetails.is_def_bank_accounts[index].type = 'delete'
            }
        },
        checkType(type){
            if (type == 'update') {
                return 'Existing'
            } else if (type == 'create'){
                return 'New'
            } else if (type == 'delete'){
                return 'Delete'
            }
        },
        async assignData(){
            const res = await this.$store.dispatch('finance/doGetIsDetails', this.$route.params.id)

            res.data.result.map( e => {

                if (e.sp_other_primary_details){
                    if (e.sp_other_primary_details.business_type_of_sub_agents){
                        e.sp_other_primary_details.business_type_of_sub_agents = JSON.parse(e.sp_other_primary_details.business_type_of_sub_agents)
                    }
                }

                if (e.sp_location.length != 0){
                    e.sp_location[0].mother_of_contract = JSON.parse(e.sp_location[0].mother_of_contract)
                    e.sp_location[0].moa_nonex = JSON.parse(e.sp_location[0].moa_nonex)
                    e.sp_location[0].moa_avp = JSON.parse(e.sp_location[0].moa_avp)
                }
                
                e.bank_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.commission_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.charges_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                // Commission Details
                if (e.is_commission.length != 0){
                    
                    e.is_commission.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })

                    let latestDate = e.is_commission.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });

                    e.is_commission = [latestDate]
                }

                // Charges Details

                if (e.is_charges.length != 0){
                    let newArr = []
                    e.is_charges.map( e=> {
                        if (e.tran_type == 'COM'){
                            newArr.push(e)
                        }
                    })
                    newArr.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })
                    let latestDate = newArr.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });
                    e.is_charges = [latestDate]
                }


                if (e.is_def_bank_accounts){
                    e.is_def_bank_accounts.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_commission){
                    e.is_commission.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_charges){
                    e.is_charges.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }

                        if (e.charge_rate){
                            e.charge_rate = parseInt(e.charge_rate)
                        }
                    })
                }

                // Branch Details
                if (e.sp_branch.data.length != 0){
                    e.sp_branch.data.map( e => {
                        if (e.pc_equip == 'Y' || e.pc_equip == 'N'){
                            e.pc_equip = 0
                        } else if (typeof e.pc_equip == 'string'){
                            parseInt(e.pc_equip)
                        }
                    })
                }

            })
            this.$store.commit('finance/SET_IS_DETAILS', res.data.result[0])
        },
        formatDate (date) {
            return format(parseISO(date), 'yyyy-MM-dd')
        },
    }
}
</script>
