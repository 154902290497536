<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :items="branchDetails"
                            :headers="headers"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            >
                             <template v-slot:item="{ item }">

                                <tr>
                                    <td>{{ item.location_code }}</td>
                                    <td>{{ item.branch_name }}</td> <!-- Include other fields here -->
                                    <td>{{ item.api }}</td>
                                    <td>{{ item.apz }}</td>
                                    <td>{{ item.created_by }}</td>

                                    <td>
                                        <v-text-field
                                            v-model="item.pc_equip"
                                            placeholder="PC Equip"
                                            label="PC Equip"
                                            single-line
                                            type="number"
                                            hide-spin-buttons
                                            hide-details
                                            :readonly="denyView"
                                        ></v-text-field>
                                    </td>

                                    <td>
                                        <v-text-field
                                            v-model="item.sol_idno"
                                            placeholder="SOL ID No"
                                            label="SOL ID No"
                                            single-line
                                            hide-details
                                            :readonly="denyView"
                                        ></v-text-field>
                                    </td>

                                    <td>
                                        <v-checkbox
                                            v-model="item.full"
                                            label="Full"
                                            color="indigo"
                                            false-value="N"
                                            true-value="Y"
                                            hide-details="auto"
                                            :disabled="denyView"
                                        ></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox
                                            v-model="item.inbound"
                                            label="In"
                                            color="indigo"
                                            false-value="N"
                                            true-value="Y"
                                            hide-details="auto"
                                            :disabled="denyView"
                                        ></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox
                                            v-model="item.outbound"
                                            label="Out"
                                            color="indigo"
                                            false-value="N"
                                            true-value="Y"
                                            hide-details="auto"
                                            :disabled="denyView"
                                        ></v-checkbox>
                                    </td>
                                    
                                    <td>
                                        <v-btn
                                            text
                                            small
                                            color="primary"
                                            @click="selectedItem = selectedItem === item ? null : item"
                                            >
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                

                                <tr v-if="selectedItem === item">
                                    <td :colspan="headers.length">
                                        <div style="padding: 10px;">
                                            <h4 class="my-3">Address</h4>
                                            <v-row>
                                                <v-col>
                                                    <p>Address 1: <br>
                                                        {{item.address_line_1}}
                                                    </p>
                                                </v-col>
                                                <v-col>
                                                    <p>Address 2: <br>
                                                        {{item.address_line_2}}
                                                    </p>
                                                </v-col>
                                                <v-col>
                                                    <p>City: <br>
                                                        {{item.city}}
                                                    </p>
                                                </v-col>
                                                <v-col>
                                                    <p>Province: <br>
                                                        {{item.province}}
                                                    </p>
                                                </v-col>
                                                <v-col>
                                                    <p>Zipcode: <br>
                                                        {{item.postal_code}}
                                                    </p>
                                                </v-col>
                                                <v-col>
                                                    <p>Country: <br>
                                                        {{item.country}}
                                                    </p>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col :cols="5"><h4> Contact Information </h4></v-col>
                                                <v-col :cols="7"><h4> Operating Hours </h4></v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col :cols="5">
                                                    <p>Landline:<br>
                                                        {{item.landline}}
                                                    </p>
                                                </v-col>

                                                <v-col :cols="1" v-for="(item, index) in item.operating_hours" :key="index">
                                                    <p>{{item.day}}<br></p>
                                                    <p v-if="item.isClosed == '1'">Close</p>
                                                    <p v-else>{{item.timeStart}} - {{item.timeEnd}}</p>
                                                </v-col>
                                            </v-row>

                                            <h4 class="my-3">Automated Location</h4>
                                            <v-row>
                                                <v-col :cols="3">
                                                    <v-autocomplete
                                                        v-model="item.atmtd_loc"
                                                        outlined
                                                        hide-details="auto"
                                                        dense
                                                        hide-spin-buttons
                                                        :items="AutomatedLocation"
                                                        :readonly="denyView"
                                                        >
                                                    </v-autocomplete>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mb-5">
                                                <v-col>
                                                    <v-checkbox
                                                        v-model="item.peso_imt"
                                                        label="Peso IMT"
                                                        color="indigo"
                                                        hide-details="auto"
                                                        false-value="N"
                                                        true-value="Y"
                                                        :disabled="denyView"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col>
                                                    <v-checkbox
                                                        v-model="item.dollar_imt"
                                                        label="Dollar IMT"
                                                        color="indigo"
                                                        hide-details="auto"
                                                        false-value="N"
                                                        true-value="Y"
                                                        :disabled="denyView"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col>
                                                    <v-checkbox
                                                        v-model="item.dollar_po"
                                                        label="Dollar Paylout"
                                                        color="indigo"
                                                        hide-details="auto"
                                                        false-value="N"
                                                        true-value="Y"
                                                        :disabled="denyView"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col>
                                                    <v-checkbox
                                                        v-model="item.peso_dmt"
                                                        label="Peso DMT"
                                                        color="indigo"
                                                        hide-details="auto"
                                                        false-value="N"
                                                        true-value="Y"
                                                        :disabled="denyView"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col>
                                                    <v-checkbox
                                                        v-model="item.dollar_dmt"
                                                        label="Dollar DMT"
                                                        color="indigo"
                                                        hide-details="auto"
                                                        false-value="N"
                                                        true-value="Y"
                                                        :disabled="denyView"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col>
                                                    <v-checkbox
                                                        v-model="item.ral_status"
                                                        label="RAL Status"
                                                        color="indigo"
                                                        hide-details="auto"
                                                        false-value="N"
                                                        true-value="Y"
                                                        :disabled="denyView"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>

                                            <h4 class="mb-3 mt-6">FLA / Manpower</h4>
                                            
                                            <v-data-table
                                                :headers="flaHeaders"
                                                :items="item.fla"
                                                :calculate-widths="true"
                                                class="ph-nonex-datatable"
                                                :search = "search"
                                                >
                                            </v-data-table>

                                            <v-row v-if="!denyView">
                                                <v-col>
                                                    <v-btn
                                                        class="mx-3 mb-5"
                                                        color="primary"
                                                        style="float:right;"
                                                        @click="updateBranch(item)"
                                                        >
                                                        <b>Update Branch</b>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>

                                        </div>
                                    </td>
                                </tr>
                                
                            </template>

                        </v-data-table>

                            <div class="text-center pb-5" v-if="isDetails.sp_branch.data.length >= 1">
                                <v-pagination
                                @input="changePage"
                                v-model="page"
                                :total-visible="5"
                                :length="branchPage.length"
                                ></v-pagination>
                            </div>
                            
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
export default {
  name: 'Users',
  components:{
  },
  data () {
    return {
        search: '',
        branchDetails: [],
        selectedItem: null,
        headers: [
            {
                text: 'Loc ID Number',
                sortable: true,
            },
            {
                text: 'Location Name',
                sortable: true,
            },
            {
                text: 'API Acct',
                sortable: true,
            },
            {
                text: 'APZ Acct',
                sortable: true,
            },
            {
                text: 'Status',
                sortable: true,
            },
            {
                text: 'PC Equiped',
                sortable: true,
            },
            {
                text: 'SOL ID No',
                sortable: true,
            },
            {
                text: '',
                sortable: true,
            },
            {
                text: '',
                sortable: true,
            },
            {
                text: '',
                sortable: true,
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
           
        ],
        flaHeaders: [
            {
                text: 'Firt Name',
                sortable: true,
                value: 'first_name'
            },
            {
                text: 'Middle Name',
                sortable: true,
                value: 'middle_name'
            },
            {
                text: 'Last Name',
                sortable: true,
                value: 'last_name'
            },
            {
                text: 'WUPOS',
                sortable: true,
                value: 'wupos_op_id'
            },
            {
                text: 'Email',
                sortable: true,
                value: 'email_address'
            },           
        ],
        pcEquiped: ['Yes','No'],
        AutomatedLocation: ['REMOTE','STAND ALONE'],
        page: 1,
        branchPage: [],
        denyView: false
    }
  },
  computed: {
    ...mapGetters({
        currUser: 'auth/currUser',
        isDetails: 'finance/isDetails',
        disable: 'finance/disable'
    })
  },
  async mounted () {
    this.assignData()

    if (this.currUser.user_role === 'encoder' || this.currUser.is_oh_approver == '1'){
        this.denyView = true
    } else if (this.currUser.is_onboarding_committee == '1' && this.isDetails.finance_status == 'Finance Pending (Final)'){
        this.denyView = true
    } else {
        this.denyView = false
    }

  },
  methods: {
    assignData () {
        this.branchDetails = []
        this.countBranchPage(this.isDetails.sp_branch)
        if (this.isDetails.sp_branch.data){

            this.isDetails.sp_branch.data.map( e => {
                if (typeof e.operating_hours == 'string') {
                    e.operating_hours = JSON.parse(e.operating_hours)
                }
            })
            
            this.branchDetails = this.isDetails.sp_branch.data
        }
    },
    countBranchPage(e){
			this.page = e.current_page
			this.branchPage = []
			if(e){
				let number = e.last_page
				for (let i = 1; i <= number; i++) {
					this.branchPage.push(i)
				}
			}
		},
    async changePage(e){
        const payload = {
            id : this.$route.params.id,
            page: e
        }
        const res = await this.$store.dispatch('finance/changePageBranch', {payload})
        if(res.status === 200) {
            console.log(res)
            this.reAssignData(res.data.result)
        }
    },
    async updateBranch(e){
        const initialPayload = {
            branch_id : e.id,
            pc_equip : e.pc_equip,
            sol_idno : e.sol_idno,
            full : e.full,
            inbound : e.inbound,
            outbound : e.outbound,
            atmtd_loc : e.atmtd_loc,
            peso_imt : e.peso_imt,
            dollar_imt : e.dollar_imt,
            dollar_po : e.dollar_po,
            peso_dmt : e.peso_dmt,
            dollar_dmt: e.dollar_dmt,
            ral_status : e.ral_status
        }
        const payload = {id: this.$route.params.id, payload: initialPayload}
        const res = await this.$store.dispatch('salesProspecting/doUpdateBranch', {payload})
        if (res.status === 200) {
            const resP = await this.$store.dispatch('finance/doGetIsDetails', this.$route.params.id)
            this.reAssignData(resP.data.result)
        }
    },
    
    async reAssignData(data){
            data.map( e => {
                if (e.sp_other_primary_details){
                    if (e.sp_other_primary_details.business_type_of_sub_agents){
                        e.sp_other_primary_details.business_type_of_sub_agents = JSON.parse(e.sp_other_primary_details.business_type_of_sub_agents)
                    }
                }

                if (e.sp_location.length != 0){
                    e.sp_location[0].mother_of_contract = JSON.parse(e.sp_location[0].mother_of_contract)
                    e.sp_location[0].moa_nonex = JSON.parse(e.sp_location[0].moa_nonex)
                    e.sp_location[0].moa_avp = JSON.parse(e.sp_location[0].moa_avp)
                }

                e.bank_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.commission_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.charges_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                // Commission Details
                if (e.is_commission.length != 0){
                    
                    e.is_commission.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })

                    let latestDate = e.is_commission.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });

                    e.is_commission = [latestDate]
                }

                // Charges Details

                if (e.is_charges.length != 0){
                    let newArr = []
                    e.is_charges.map( e=> {
                        if (e.tran_type == 'COM'){
                            newArr.push(e)
                        }
                    })
                    newArr.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })
                    let latestDate = newArr.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });
                    e.is_charges = [latestDate]
                }


                if (e.is_def_bank_accounts){
                    e.is_def_bank_accounts.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_commission){
                    e.is_commission.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_charges){
                    e.is_charges.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }

                        if (e.charge_rate){
                            e.charge_rate = parseInt(e.charge_rate)
                        }
                    })
                }

                // Branch Details
                if (e.sp_branch.data.length != 0){
                    e.sp_branch.data.map( e => {
                        if (e.pc_equip == 'Y' || e.pc_equip == 'N'){
                            e.pc_equip = 0
                        } else if (typeof e.pc_equip == 'string'){
                            parseInt(e.pc_equip)
                        }
                    })
                }
            })
            this.$store.commit('finance/SET_IS_DETAILS', data[0])
            this.assignData()
        },
        formatDate (date) {
            return format(parseISO(date), 'yyyy-MM-dd')
        },
    }
}
</script>

<style scoped>
.initial-status {
  color: blue;
}

.final-status {
  color: green;
}
</style>