<template>
    <div>
        <v-row class="mb-5" v-if="bankCreate.length != 0 || bankUpdate.length != 0 || bankDelete.length != 0">
            <h3 style="color: blue">Bank Accounts Audit Trail</h3>
        </v-row>
        <v-row v-if="bankCreate.length != 0" class="mb-3">
            <h4>New Bank Accounts</h4>
        </v-row>
        <v-row v-for="(item, index) in bankCreate" :key="index" class="mr-5">
                
                <v-col :cols="2">
                    <v-text-field
                        label="Status"
                        :value="checkType(item.type)"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                 <v-col :cols="3">
                    <v-text-field
                        label="Account Name"
                        :value="checkName(item)"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="1">
                    <v-text-field
                        label="BP ID Number"
                        v-model="item.bp_idno"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="1">
                    <v-text-field
                        label="SP Number"
                        v-model="item.sales_prospects_id"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="Created By"
                        v-model="item.created_by"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="Approve Date"
                        :value="formatDate(item.approved_date)"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
            <v-col :cols="1" class="col-xs-1 text-center">
                    <v-btn
                        color="primary"
                        small
                        @click="item.showDetails = !item.showDetails"
                        >
                        <b v-if="item.showDetails">Hide Details <v-icon small>mdi-arrow-up-bold</v-icon></b>
                        <b v-else>View Details <v-icon small>mdi-arrow-down-bold</v-icon></b>
                    </v-btn>
                </v-col>
                
                <v-col>
                    <div v-if="item.showDetails">
                        <h5 class="mb-5">Bank Details</h5>
                        <v-row class="mx-1 mb-1 pr-15">
                            <v-col :cols="1">
                                <v-text-field
                                    dense
                                    label="Account Currency"
                                    v-model="item.new_value.acct_cur"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Account Name"
                                    v-model="item.new_value.acct_name"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Account Number"
                                    v-model="item.new_value.acct_no"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Account Type"
                                    v-model="item.new_value.acct_type"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="1">
                                <v-text-field
                                    dense
                                    label="Bank Code"
                                    v-model="item.new_value.bank_code"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                                <h5>{{ getBankName(item.new_value.bank_code) }}</h5>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="BP ID Number"
                                    v-model="item.new_value.bp_idno"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Signatory"
                                    v-model="item.new_value.signatory"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
        </v-row>

        <hr v-if="bankCreate.length != 0">

        <v-row v-if="bankUpdate.length != 0" class="mb-3 mt-5">
            <h4>Update Bank Accounts</h4>
        </v-row>

        <v-row v-for="(item, index) in bankUpdate" :key="index" class="mr-5">
            <v-col :cols="2">
                <v-text-field
                    label="Status"
                    :value="checkType(item.type)"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="3">
                <v-text-field
                    label="Account Name"
                    :value="checkName(item)"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="1">
                <v-text-field
                    label="BP ID Number"
                    v-model="item.bp_idno"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="1">
                <v-text-field
                    label="SP Number"
                    v-model="item.sales_prospects_id"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-text-field
                    label="Created By"
                    v-model="item.created_by"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-text-field
                    label="Approve Date"
                    :value="formatDate(item.approved_date)"
                    hide-details="auto"
                    outlined
                    readonly
                    >
                </v-text-field>
            </v-col>
        <v-col :cols="1" class="col-xs-1 text-center">
                <v-btn
                    color="primary"
                    @click="item.showDetails = !item.showDetails"
                    small
                    >
                    <b v-if="item.showDetails">Hide Details <v-icon small>mdi-arrow-up-bold</v-icon></b>
                    <b v-else>View Details <v-icon small>mdi-arrow-down-bold</v-icon></b>
                </v-btn>
            </v-col>
            
            <v-col>
                <div v-if="item.showDetails">
                    <h5 class="mb-5">New Bank Details</h5>
                    <v-row class="mx-1 mb-1 pr-15">
                        <v-col :cols="1">
                            <v-text-field
                                dense
                                label="Account Currency"
                                v-model="item.new_value.acct_cur"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                        <v-col :cols="2">
                            <v-text-field
                                dense
                                label="Account Name"
                                v-model="item.new_value.acct_name"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                        <v-col :cols="2">
                            <v-text-field
                                dense
                                label="Account Number"
                                v-model="item.new_value.acct_no"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                        <v-col :cols="2">
                            <v-text-field
                                dense
                                label="Account Type"
                                v-model="item.new_value.acct_type"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                        <v-col :cols="2">
                            <v-text-field
                                dense
                                label="Bank Code"
                                v-model="item.new_value.bank_code"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                            <h5>{{ getBankName(item.new_value.bank_code) }}</h5>
                        </v-col>
                        <v-col :cols="1">
                            <v-text-field
                                dense
                                label="BP ID Number"
                                v-model="item.new_value.bp_idno"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                        <v-col :cols="2">
                            <v-text-field
                                dense
                                label="Signatory"
                                v-model="item.new_value.signatory"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <h5 class="mb-5">Previous Bank Details</h5>
                    <v-row class="mx-1 mb-1 pr-15">
                        <v-col :cols="1">
                            <v-text-field
                                dense
                                label="Account Currency"
                                v-model="item.prev_value.acct_cur"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                        <v-col :cols="2">
                            <v-text-field
                                dense
                                label="Account Name"
                                v-model="item.prev_value.acct_name"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                        <v-col :cols="2">
                            <v-text-field
                                dense
                                label="Account Number"
                                v-model="item.prev_value.acct_no"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                        <v-col :cols="2">
                            <v-text-field
                                dense
                                label="Account Type"
                                v-model="item.prev_value.acct_type"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                        <v-col :cols="2">
                            <v-text-field
                                dense
                                label="Bank Code"
                                v-model="item.prev_value.bank_code"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                            <h5>{{ getBankName(item.prev_value.bank_code) }}</h5>
                        </v-col>
                        <v-col :cols="1">
                            <v-text-field
                                dense
                                label="BP ID Number"
                                v-model="item.prev_value.bp_idno"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                        <v-col :cols="2">
                            <v-text-field
                                dense
                                label="Signatory"
                                v-model="item.prev_value.signatory"
                                hide-details="auto"
                                outlined
                                readonly
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <hr v-if="bankUpdate.length != 0">

        <v-row v-if="bankDelete.length != 0" class="mb-3 mt-10">
            <h4>Delete Bank Accounts</h4>
        </v-row>
        <v-row v-for="(item, index) in bankDelete" :key="index" class="mr-5">
                <v-col :cols="2">
                    <v-text-field
                        label="Status"
                        :value="checkType(item.type)"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="3">
                    <v-text-field
                        label="Account Name"
                        :value="checkName(item)"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="1">
                    <v-text-field
                        label="BP ID Number"
                        v-model="item.bp_idno"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="1">
                    <v-text-field
                        label="SP Number"
                        v-model="item.sales_prospects_id"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="Created By"
                        v-model="item.created_by"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        label="Approve Date"
                        :value="formatDate(item.approved_date)"
                        hide-details="auto"
                        outlined
                        readonly
                        >
                    </v-text-field>
                </v-col>
            <v-col :cols="1" class="col-xs-1 text-center">
                    <v-btn
                        color="primary"
                        small
                        @click="item.showDetails = !item.showDetails"
                        >
                        <b v-if="item.showDetails">Hide Details <v-icon small>mdi-arrow-up-bold</v-icon></b>
                        <b v-else>View Details <v-icon small>mdi-arrow-down-bold</v-icon></b>
                    </v-btn>
                </v-col>
                
                <v-col>
                    <div v-if="item.showDetails">
                        <h5 class="mb-5">Bank Details</h5>
                        <v-row class="mx-1 mb-1 pr-15">
                            <v-col :cols="1">
                                <v-text-field
                                    dense
                                    label="Account Currency"
                                    v-model="item.prev_value.acct_cur"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Account Name"
                                    v-model="item.prev_value.acct_name"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Account Number"
                                    v-model="item.prev_value.acct_no"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Account Type"
                                    v-model="item.prev_value.acct_type"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Bank Code"
                                    v-model="item.prev_value.bank_code"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                                <h5>{{ getBankName(item.prev_value.bank_code) }}</h5>
                            </v-col>
                            <v-col :cols="1">
                                <v-text-field
                                    dense
                                    label="BP ID Number"
                                    v-model="item.prev_value.bp_idno"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Signatory"
                                    v-model="item.prev_value.signatory"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
        </v-row>

       <CommissionAuditTrail :item="commissionDetails"> </CommissionAuditTrail>

       <ChargesAuditTrail :item="chargesDetails"> </ChargesAuditTrail>

        <hr v-if="bankDelete.length != 0">

        <v-row class="mt-5">
            <v-col>
                <v-btn
                    v-if="bankArchive.length != 0"
                    class="mx-3"
                    color="orange"
                    style="float:left;"
                    @click="archiveDialog = true"
                    >
                    <b>Bank Archive</b>
                </v-btn>
                <v-btn
                    v-if="commissionDetails.commissionArchive.length != 0"
                    class="mx-3"
                    color="orange"
                    style="float:left;"
                    @click="commissionDialog = true"
                    >
                    <b>Commission Archive</b>
                </v-btn>
                <v-btn
                    v-if="chargesDetails.chargesArchive.length != 0"
                    class="mx-3"
                    color="orange"
                    style="float:left;"
                    @click="chargeDialog = true"
                    >
                    <b>Charges Archive</b>
                </v-btn>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog
            v-model="chargeDialog"
            width="80%"
            >
                <v-card class="px-5 pb-5">
                    <v-card-title class="py-8">
                    <span class="text-h5 pb-7" id="dialog_title">Charges Audit Trail Archive</span>
                    </v-card-title>

                    <v-card-text>

                        <v-row class="mb-5 mr-5" v-for="(item, index) in chargesDetails.chargesArchive" :key="index" >
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Action"
                                    :value="chargeType(item.type)"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="BP ID Number"
                                    v-model="item.bp_idno"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="SP Number"
                                    v-model="item.sales_prospects_id"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="3">
                                <v-text-field
                                    dense
                                    label="Created By"
                                    v-model="item.created_by"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Approve Date"
                                    :value="formatDate(item.approved_date)"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="1" class="col-xs-1 text-center">
                                    <v-btn
                                        color="primary"
                                        @click="item.showDetails = !item.showDetails"
                                        small
                                        >
                                        <b v-if="item.showDetails">Hide Details <v-icon small>mdi-arrow-up-bold</v-icon></b>
                                        <b v-else>View Details <v-icon small>mdi-arrow-down-bold</v-icon></b>
                                    </v-btn>
                            </v-col>
                            
                            <v-col>
                                <div v-if="item.showDetails">
                                    <h5 v-if="item.new_value.bp_idno != null" class="mb-5">New Charges Details</h5>
                                    <v-row v-if="item.new_value.bp_idno != null" class="mx-1 mb-1 pr-15">
                                        <!-- <v-col :cols="4">
                                            <v-text-field
                                                dense
                                                label="Charge Amount"
                                                v-model="item.new_value.charge_amount"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col> -->
                                        <v-col :cols="4">
                                            <v-text-field
                                                dense
                                                label="Charge Rage"
                                                v-model="item.new_value.charge_rate"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="4">
                                            <v-text-field
                                                dense
                                                label="Transaction Type"
                                                v-model="item.new_value.tran_type"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <h5 v-if="item.prev_value.bp_idno != null" class="mb-5">Previous Charges Details</h5>
                                    <v-row v-if="item.prev_value.bp_idno != null" class="mx-1 mb-1 pr-15">
                                        <!-- <v-col :cols="4">
                                            <v-text-field
                                                dense
                                                label="Charge Amount"
                                                v-model="item.prev_value.charge_amount"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col> -->
                                        <v-col :cols="4">
                                            <v-text-field
                                                dense
                                                label="Charge Rage"
                                                v-model="item.prev_value.charge_rate"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="4">
                                            <v-text-field
                                                dense
                                                label="Transaction Type"
                                                v-model="item.prev_value.tran_type"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                                <hr v-if="chargesDetails.chargesArchive.length != 0">
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined color="primary"
                            text
                            @click="chargeDialog = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog
            v-model="commissionDialog"
            width="80%"
            >
                <v-card class="px-5 pb-5">
                    <v-card-title class="py-8">
                    <span class="text-h5 pb-7" id="dialog_title">Commission Audit Trail Archive</span>
                    </v-card-title>

                    <v-card-text>

                        <v-row class="mb-5 mr-5" v-for="(item, index) in commissionDetails.commissionArchive" :key="index" >
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Action"
                                    :value="commissionType(item.type)"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="BP ID Number"
                                    v-model="item.bp_idno"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="SP Number"
                                    v-model="item.sales_prospects_id"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="3">
                                <v-text-field
                                    dense
                                    label="Created By"
                                    v-model="item.created_by"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Approve Date"
                                    :value="formatDate(item.approved_date)"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="1" class="col-xs-1 text-center">
                                    <v-btn
                                        color="primary"
                                        @click="item.showDetails = !item.showDetails"
                                        small
                                        >
                                        <b v-if="item.showDetails">Hide Details <v-icon small>mdi-arrow-up-bold</v-icon></b>
                                        <b v-else>View Details <v-icon small>mdi-arrow-down-bold</v-icon></b>
                                    </v-btn>
                            </v-col>
                            
                            <v-col>
                                <div v-if="item.showDetails">
                                    <h5 v-if="item.new_value.bp_idno != null" class="mb-5">New Commission Details</h5>
                                    <v-row v-if="item.new_value.bp_idno != null" class="mx-1 mb-1 pr-15">
                                        <v-col :cols="6">
                                            <v-text-field
                                                dense
                                                label="Commission Rate"
                                                v-model="item.new_value.comm_rate"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <h5 v-if="item.prev_value.bp_idno != null" class="mb-5">Previous Commission Details</h5>
                                    <v-row v-if="item.prev_value.bp_idno != null" class="mx-1 mb-1 pr-15">
                                        <v-col :cols="6">
                                            <v-text-field
                                                dense
                                                label="Commission Rate"
                                                v-model="item.prev_value.comm_rate"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                                <hr v-if="commissionDetails.commissionArchive.length != 0">
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined color="primary"
                            text
                            @click="commissionDialog = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog
            v-model="archiveDialog"
            width="80%"
            >
                <v-card class="px-5 pb-5">
                    <v-card-title class="py-8">
                    <span class="text-h5 pb-7" id="dialog_title">Bank Audit Trail Archive</span>
                    </v-card-title>

                    <v-card-text>

                        <v-row class="mb-5 mr-5" v-for="(item, index) in bankArchive" :key="index" >
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Action"
                                    :value="checkType(item.type)"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="3">
                                <v-text-field
                                    label="Account Name"
                                    :value="checkName(item)"
                                    hide-details="auto"
                                    dense
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="1">
                                <v-text-field
                                    dense
                                    label="BP ID Number"
                                    v-model="item.bp_idno"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="1">
                                <v-text-field
                                    dense
                                    label="SP Number"
                                    v-model="item.sales_prospects_id"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Created By"
                                    v-model="item.created_by"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="2">
                                <v-text-field
                                    dense
                                    label="Approve Date"
                                    :value="formatDate(item.approved_date)"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col :cols="1" class="col-xs-1 text-center">
                                    <v-btn
                                        color="primary"
                                        @click="item.showDetails = !item.showDetails"
                                        small
                                        >
                                        <b v-if="item.showDetails">Hide Details <v-icon small>mdi-arrow-up-bold</v-icon></b>
                                        <b v-else>View Details <v-icon small>mdi-arrow-down-bold</v-icon></b>
                                    </v-btn>
                            </v-col>
                            
                            <v-col>
                                <div v-if="item.showDetails">
                                    <h5 v-if="item.new_value.bp_idno != null" class="mb-5">New Bank Details</h5>
                                    <v-row v-if="item.new_value.bp_idno != null" class="mx-1 mb-1 pr-15">
                                        <v-col :cols="1">
                                            <v-text-field
                                                dense
                                                label="Account Currency"
                                                v-model="item.new_value.acct_cur"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="2">
                                            <v-text-field
                                                dense
                                                label="Account Name"
                                                v-model="item.new_value.acct_name"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="2">
                                            <v-text-field
                                                dense
                                                label="Account Number"
                                                v-model="item.new_value.acct_no"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="2">
                                            <v-text-field
                                                dense
                                                label="Account Type"
                                                v-model="item.new_value.acct_type"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="1">
                                            <v-text-field
                                                dense
                                                label="Bank Code"
                                                v-model="item.new_value.bank_code"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                            <h5>{{ getBankName(item.new_value.bank_code) }}</h5>
                                        </v-col>
                                        <v-col :cols="2">
                                            <v-text-field
                                                dense
                                                label="BP ID Number"
                                                v-model="item.new_value.bp_idno"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="2">
                                            <v-text-field
                                                dense
                                                label="Signatory"
                                                v-model="item.new_value.signatory"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <h5 v-if="item.prev_value.bp_idno != null" class="mb-5">Previous Bank Details</h5>
                                    <v-row v-if="item.prev_value.bp_idno != null" class="mx-1 mb-1 pr-15">
                                        <v-col :cols="1">
                                            <v-text-field
                                                dense
                                                label="Account Currency"
                                                v-model="item.prev_value.acct_cur"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="2">
                                            <v-text-field
                                                dense
                                                label="Account Name"
                                                v-model="item.prev_value.acct_name"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="2">
                                            <v-text-field
                                                dense
                                                label="Account Number"
                                                v-model="item.prev_value.acct_no"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="2">
                                            <v-text-field
                                                dense
                                                label="Account Type"
                                                v-model="item.prev_value.acct_type"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="2">
                                            <v-text-field
                                                dense
                                                label="Bank Code"
                                                v-model="item.prev_value.bank_code"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                            <h5>{{ getBankName(item.new_value.bank_code) }}</h5>
                                        </v-col>
                                        <v-col :cols="1">
                                            <v-text-field
                                                dense
                                                label="BP ID Number"
                                                v-model="item.prev_value.bp_idno"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col :cols="2">
                                            <v-text-field
                                                dense
                                                label="Signatory"
                                                v-model="item.prev_value.signatory"
                                                hide-details="auto"
                                                outlined
                                                readonly
                                                >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                                <hr v-if="bankArchive.length != 0">
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined color="primary"
                            text
                            @click="archiveDialog = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
import  CommissionAuditTrail from './CommissionAuditTrail.vue'
import  ChargesAuditTrail from './ChargesAuditTrail.vue'

export default{
    components:{
        CommissionAuditTrail: CommissionAuditTrail,
        ChargesAuditTrail: ChargesAuditTrail
    },
    data: () => ({
        archiveDialog: false,
        showDetails: false,
        commissionDialog: false,
        chargeDialog: false,
        bankCreate: [],
        bankUpdate: [],
        bankDelete: [],
        bankArchive: [],
        bankCode :[
            { code: "ING", name: "ING BANK NV" },
            { code: "ESB", name: "EQUICOM SAVINGS BANK" },
            { code: "MICB", name: "MEGA INTERNATIONAL COMMERCIAL BANK" },
            { code: "AUBP", name: "ASIAN UNITED BANK PESO" },
            { code: "AUBD", name: "ASIAN UNITED BANK DOLLAR" },
            { code: "MAYB", name: "MAYBANK" },
            { code: "BOA", name: "BANK OF AMERICA" },
            { code: "MBTC", name: "METROPOLITAN BANK & TRUST CO." },
            { code: "ALLI", name: "ALLIED BANK" },
            { code: "UCPB", name: "UNITED COCONUT PLANTERS BANK" },
            { code: "FEBT", name: "FAR EAST BANK AND TRUST COMPANY" },
            { code: "URBN", name: "URBAN BANK" },
            { code: "LNBP", name: "LAND BANK OF THE PHILIPPINES" },
            { code: "PNBB", name: "PHILIPPINE NATIONAL BANK" },
            { code: "MTBP", name: "METROBANK PESO" },
            { code: "RCBC", name: "RIZAL COMMERCIAL BANKING CORP." },
            { code: "CHIB", name: "CHINABANK" },
            { code: "CITI", name: "CITIBANK" },
            { code: "BPIS", name: "BANK OF THE PHILIPPINE ISLAND" },
            { code: "ALDB", name: "ALLIED BANK DOLLAR" },
            { code: "ALIP", name: "ALLIED BANK PESO" },
            { code: "MYBD", name: "MAYBANK" },
            { code: "EWB", name: "EASTWEST BANK" },
            { code: "BPID", name: "BANK OF THE PHILIPPINE ISLAND" },
            { code: "PDB", name: "CHINA BANK SAVINGS INC." },
            { code: "PBBD", name: "PHILIPPINE BUSINESS BANK DOLLAR" },
            { code: "PBBP", name: "PHILIPPINE BUSINESS BANK PESO" },
            { code: "DBD", name: "DEUTSCHE BANK DOLLAR" },
            { code: "DBP", name: "DEUTSCHE BANK PESO" },
            { code: "SECD", name: "SECURITY BANK & TRUST COMPANY" },
            { code: "SECP", name: "SECURITY BANK & TRUST COMPANY" },
            { code: "PBB", name: "PUBLIC BANK BERHAD" },
            { code: "SBA", name: "STERLING BANK" },
            { code: "AB", name: "ALLIED BANK" },
            { code: "CSBP", name: "CITY SAVINGS BANK PESO" },
            { code: "CSBD", name: "CITY SAVINGS BANK DOLLAR" },
            { code: "SBTC", name: "SECURITY BANK & TRUST COMPANY" },
            { code: "DB", name: "DEUTSCHE BANK" },
            { code: "CITD", name: "CITIBANK DOLLAR" },
            { code: "CITP", name: "CITIBANK PESO" },
            { code: "SCBD", name: "SECURITY BANK & TRUST COMPANY" },
            { code: "SCBP", name: "SECURITY BANK & TRUST COMPANY" },
            { code: "BOCP", name: "BANK OF COMMERCE" },
            { code: "BOCD", name: "BANK OF COMMERCE (US $)" },
            { code: "JPMB", name: "JPMORGAN CHASE BANK" },
            { code: "MTBD", name: "METROBANK DOLLAR" },
            { code: "HSBD", name: "HSBC DOLLAR" },
            { code: "HSBP", name: "HSBC PESO" },
            { code: "SCB", name: "STANDARD CHARTERED BANK" },
            { code: "MSBP", name: "MALAYAN BANK SAVINGS PESO" },
            { code: "MSBD", name: "MALAYAN BANK SAVINGS DOLLAR" },
            { code: "BOC", name: "BANK OF COMMERCE" },
            { code: "PBBB", name: "PHILIPPINE BUSINESS BANK" },
            { code: "PVCD", name: "PHIL VETERANS BANK" },
            { code: "PCOM", name: "PB COM" },
            { code: "ROBB", name: "ROBINSONSBANK" },
            { code: "SBTD", name: "SECURITY BANK & TRUST COMPANY" },
            { code: "LBP2", name: "LAND BANK OF THE PHILIPPINE (Formerly UCPB)" },
            { code: "SMBC", name: "SUMITOMO MITSUI BANKING CORPORATION" },
            { code: "UOBL", name: "UNITED OVERSEAS BANK LIMITED" },
            { code: "MB", name: "MIZUHO BANK" },
            { code: "PSB", name: "PHILIPPINE SAVINGS BANK" },
            { code: "CBSI", name: "CHINABANK SAVINGS INC" },
            { code: "BANG", name: "BANGKOK BANK PUBLIC COMPANY LIMITED" },
        ],
        commissionDetails: {
            commissionCreate: [],
            commissionUpdate: [],
            commissionDelete: [],
            commissionArchive: [],
        },
        chargesDetails: {
            chargesCreate: [],
            chargesUpdate: [],
            chargesDelete: [],
            chargesArchive: [],
        },
    }),
    computed: {
        ...mapGetters({
            isDetails: 'finance/isDetails'
        })
    },
    mounted () {
        this.assignData()
	},
    methods: {
        checkName(item){
            if (item.prev_value){
                if (item.prev_value.acct_name == null){
                    return item.new_value.acct_name
                } else {
                    return item.prev_value.acct_name
                }
            } else {
                return item.new_value.acct_name
            }
        },
        getBankName(code) {
            const selectedBank = this.bankCode.find(bank => bank.code === code);
            return selectedBank ? selectedBank.name : '';
        },
        assignData() {
            this.bankCreate = []
            this.bankUpdate = []
            this.bankDelete = []
            this.bankArchive = []
            
            this.isDetails.bank_audit_trail.map((e) => {
                const newItem = { ...e, showDetails: false }; // Add the showDetails property

                if (e.approved_by == null && e.type == 'create') {
                this.bankCreate.push(newItem);
                } else if (e.approved_by == null && e.type == 'update') {
                this.bankUpdate.push(newItem);
                } else if (e.approved_by == null && e.type == 'delete') {
                this.bankDelete.push(newItem);
                } else if (e.approved_by != null){
                    this.bankArchive.push(newItem)
                }
            })

            this.commissionDetails.commissionCreate = []
            this.commissionDetails.commissionUpdate = []
            this.commissionDetails.commissionDelete = []
            this.commissionDetails.commissionArchive = []
            
            this.isDetails.commission_audit_trail.map((e) => {
                const newItem = { ...e, showDetails: false }; // Add the showDetails property

                if (e.approved_by == null && e.type == 'create') {
                this.commissionDetails.commissionCreate.push(newItem);
                } else if (e.approved_by == null && e.type == 'update') {
                this.commissionDetails.commissionUpdate.push(newItem);
                } else if (e.approved_by == null && e.type == 'delete') {
                this.commissionDetails.commissionDelete.push(newItem);
                } else if (e.approved_by != null){
                    this.commissionDetails.commissionArchive.push(newItem)
                }
            })
            
            this.chargesDetails.chargesCreate = []
            this.chargesDetails.chargesUpdate = []
            this.chargesDetails.chargesDelete = []
            this.chargesDetails.chargesArchive = []
            
            this.isDetails.charges_audit_trail.map((e) => {
                const newItem = { ...e, showDetails: false }; // Add the showDetails property

                if (e.approved_by == null && e.type == 'create') {
                this.chargesDetails.chargesCreate.push(newItem);
                } else if (e.approved_by == null && e.type == 'update') {
                this.chargesDetails.chargesUpdate.push(newItem);
                } else if (e.approved_by == null && e.type == 'delete') {
                this.chargesDetails.chargesDelete.push(newItem);
                } else if (e.approved_by != null){
                    this.chargesDetails.chargesArchive.push(newItem)
                }
            })
        },
        formatDate (date) {
            if (date != null){
                return format(parseISO(date), 'yyyy-MM-dd')
            }
        },
        checkType(type){
            if (type == 'create'){
                return 'New Bank Account'
            } else if (type == 'update'){
                return 'Update Bank Details'
            } else if (type == 'delete'){
                return 'Delete Bank Account'
            }
        },
        commissionType(type){
            if (type == 'create'){
                return 'New Commission'
            } else if (type == 'update'){
                return 'Update Commission Details'
            } else if (type == 'delete'){
                return 'Delete Commission'
            }
        },
        chargeType(type){
            if (type == 'create'){
                return 'New Charges'
            } else if (type == 'update'){
                return 'Update Charges Details'
            } else if (type == 'delete'){
                return 'Delete Charges'
            }
        },
    }
}
</script>
