<template>
    <div>

        <v-dialog v-model="snackbar" persistent max-width="500">
            <v-card>
                <v-card-text style="background-color: red" class="scrollable-content error">
                    <h2 style="color: white" class="pt-5">Missing Details!</h2>
                    <v-scroll-y style="max-height: 200px; max-width: 200px; overflow: hidden">
                        <div class="pt-5">
                            <section><h5 v-for="(item, index) in errorMessage" :key="index" style="color: white" class="mb-1">- {{item}}</h5></section>
                        </div>
                    </v-scroll-y>
                </v-card-text>
                <v-card-actions style="background-color: red" class="error">
                    <v-spacer></v-spacer>
                    <v-btn color="white" text @click="goBack">Back</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <PrpInformation v-if="componentLoaded"></PrpInformation>
        
        <v-row v-if="currUser.user_role == 'encoder' && !disable && componentLoaded">
            <v-col>
                <v-btn
                    class="mx-3 mb-10"
                    color="success"
                    style="float:right;"
                    @click="submitEncoder"
                    >
                    <b>Submit</b>
                </v-btn>
            </v-col>
        </v-row>


        <v-row v-if="currUser.is_onboarding_committee == 1 && !disable && !$route.query.onboarding && componentLoaded && this.isDetails.finance_status == 'Finance Pending (Initial)'">
            <v-col>
                <v-btn
                    class="mr-4"
                    color="error"
                    style="float:right;"
                    @click="approverDialog = true"
                    >
                    <b>Disapprove</b>
                </v-btn>
                <v-btn
                    class="mx-2 mb-10"
                    color="success"
                    style="float:right;"
                    @click="approveOnboarding"
                    >
                    <b>Approve</b>
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="currUser.is_oh_approver == '1' && componentLoaded && this.isDetails.finance_status == 'Finance Pending (Final)'">
            <v-col>
                <v-btn
                    class="mr-4"
                    color="error"
                    style="float:right;"
                    @click="approverDialog = true"
                    >
                    <b>Disapprove</b>
                </v-btn>
                <v-btn
                    class="mx-2 mb-10"
                    color="success"
                    style="float:right;"
                    @click="approveOnboarding"
                    >
                    <b>Approve</b>
                </v-btn>
            </v-col>
        </v-row>


        <v-dialog v-model="encoderDialog" persistent max-width="800">
            <v-card class="pt-5">
                <v-card-text class="my-5">
                     <span class="text-h5" id="dialog_title">Attach latest MOA Files</span>
                </v-card-text>
                                
                <v-row class="mx-5 mb-3">
                    <v-col :cols="12">
                        <v-file-input
                            v-model="details.mother_of_contract.file"
                            label="Western Union"
                            prepend-icon="mdi-paperclip"
                            dense
                            outlined
                            hide-details="auto"
                            >
                        </v-file-input>
                    </v-col>
                    <v-col :cols="12">
                        <v-file-input
                            v-model="details.moa_avp.file"
                            label="AVP MOA"
                            prepend-icon="mdi-paperclip"
                            dense
                            outlined
                            hide-details="auto"
                            >
                        </v-file-input>
                    </v-col>
                    <v-col :cols="12">
                        <v-file-input
                            v-model="details.moa_nonex.file"
                            label="NONEX MOA"
                            prepend-icon="mdi-paperclip"
                            dense
                            outlined
                            hide-details="auto"
                            >
                        </v-file-input>
                    </v-col>
                </v-row>

                <v-card-actions class="pb-5 py-5">
                    <v-btn
                        class="mx-3"
                        color="primary"
                        style="float:right;"
                        outlined
                        @click="encoderDialog = false"
                        >
                        <b>Cancel</b>
                    </v-btn>
                        <v-spacer></v-spacer>
                    <v-btn
                        v-if="details.mother_of_contract.file || details.moa_avp.file || details.moa_nonex.file"
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        @click="callEncoderSubmit(), encoderDialog = false"
                        >
                        <b>Submit for Approval</b>
                    </v-btn>
                    <v-btn
                        v-else
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        disabled
                        >
                        <b>Submit for Approval</b>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="approverDialog" persistent max-width="800">
            <v-card class="pt-5">
                <v-card-text class="my-5">
                     <span class="text-h5" id="dialog_title">Reason for Disapproval</span>
                </v-card-text>
                                
                <v-row class="mx-5 mb-3">
                    <v-col>
                        <v-textarea
                            label="Remarks"
                            auto-grow
                            outlined
                            v-model="remarks"
                        ></v-textarea>
                    </v-col>
                </v-row>

                <v-card-actions class="pb-5 py-5">
                    <v-btn
                        class="mx-3"
                        color="primary"
                        style="float:right;"
                        outlined
                        @click="approverDialog = false"
                        >
                        <b>Cancel</b>
                    </v-btn>
                        <v-spacer></v-spacer>
                    <v-btn
                        v-if="remarks != ''"
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        @click="disapproveOnboarding(), approverDialog = false"
                        >
                        <b>Disapprove</b>
                    </v-btn>
                    <v-btn
                        v-else
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        disabled
                        >
                        <b>Disapprove</b>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
import  PrpInformation from './PrpInformation.vue'
import jsonToFormData from 'json-form-data';

export default {
    computed: {
        ...mapGetters({
            currUser: 'auth/currUser',
            isDetails: 'finance/isDetails',
            disable: 'finance/disable'
        })
    },
    data: () => ({
        componentLoaded: false,
        encoderDialog: false,
        approverDialog: false,
        details: {
            mother_of_contract: {
                file: null
            },
            moa_avp: {
                file : null
            },
            moa_nonex: {
                file: null
            },
            charges: '',
            commission: ''
        },
        remarks: '',
        snackbar: false,
        errorMessage: []
    }),
    async created () {
		this.assignData()
    },
    methods: {
        goBack() {
            this.snackbar = false
            history.back();
        },
        async submitEncoder(){
            if (this.isDetails.is_commission.length == 0 && this.isDetails.is_charges.length == 0){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Commission and Charges is empty!','messagetype':'error'});
            } else if (this.isDetails.is_commission.length == 0){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Commission is empty!','messagetype':'error'});
            } else if (this.isDetails.is_charges.length == 0){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Charges is empty!','messagetype':'error'});
            } else if (this.isDetails.is_charges.length >= 1){
                this.isDetails.is_charges.map( e => {
                    let chargeValue = [2,5,10,15]
                    if (!chargeValue.includes(e.charge_rate) && e.type != 'delete'){
                        this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Charge Rate is empty!','messagetype':'error'});
                    } else {
                        this.encoderDialog = true
                    }
                })
            } else {
                this.encoderDialog = true
            }
        },
        async callEncoderSubmit(){

            this.details.commission = this.isDetails.is_commission
            this.details.charges = this.isDetails.is_charges

            var formData = new FormData();

            var options = {
                initialFormData: formData,
                howLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function (value) {
                    if (typeof value === 'boolean') {
                        return +value ? '1' : '0';
                    }
                    return value;
                }
            }
            var convertedFormData = jsonToFormData(this.details, options);
            
            const payload = { id: this.$route.params.id, payload: convertedFormData };
            const res = await this.$store.dispatch('finance/doEncoderSubmit', {payload})
            if (res.status == 200){
                if(res.data.result == 'No changes detected, no request required.'){
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.data.result,'messagetype':'orange'});
                } else {
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Submitted Successfully.','messagetype':'success'});
                    this.$router.push('/finance-encoder');
                }
                
            } else if (res.status === 206 || res.code === 400 || res.code === 422 || res.code === 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            } else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
            }
        },
        async approveOnboarding(){
            const res = await this.$store.dispatch('finance/doApproveOnboarding', this.$route.params.id)
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Approved Successfully.','messagetype':'success'});
                this.$router.push('/finance-onboarding/')
            } else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Something went wrong!','messagetype':'error'});
            }
        },
        async disapproveOnboarding(){
            const payload = {
                id : this.$route.params.id,
                finalPayload: {
                    remarks: this.remarks
                }
            }
            const res = await this.$store.dispatch('finance/doDisapproveOnboarding', {payload})
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Disapproved Successfully!.','messagetype':'success'});
                this.$router.push('/finance-onboarding/')
            } else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Something went wrong!','messagetype':'error'});
            }
        },
        async assignData(){
            const res = await this.$store.dispatch('finance/doGetIsDetails', this.$route.params.id)

            res.data.result.map( e => {
                if (e.sp_other_primary_details){
                    if (e.sp_other_primary_details.business_type_of_sub_agents){
                        e.sp_other_primary_details.business_type_of_sub_agents = JSON.parse(e.sp_other_primary_details.business_type_of_sub_agents)
                    }
                }

                if (e.sp_location.length != 0){
                    e.sp_location[0].mother_of_contract = JSON.parse(e.sp_location[0].mother_of_contract)
                    e.sp_location[0].moa_nonex = JSON.parse(e.sp_location[0].moa_nonex)
                    e.sp_location[0].moa_avp = JSON.parse(e.sp_location[0].moa_avp)
                }
                
                e.bank_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.commission_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.charges_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                // Commission Details
                if (e.is_commission.length != 0){
                    
                    e.is_commission.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })

                    let latestDate = e.is_commission.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });

                    e.is_commission = [latestDate]
                }

                // Charges Details

                if (e.is_charges.length != 0){
                    let newArr = []
                    e.is_charges.map( e=> {
                        if (e.tran_type == 'COM'){
                            newArr.push(e)
                        }
                    })
                    newArr.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })
                    let latestDate = newArr.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });
                    e.is_charges = [latestDate]
                }


                if (e.is_def_bank_accounts){
                    e.is_def_bank_accounts.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }

                        if(e.bank_code){
                            e.bank_code = e.bank_code.replace(/\s/g, '');
                        }
                    })
                }

                if (e.is_commission){
                    e.is_commission.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_charges){
                    e.is_charges.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }

                        if (e.charge_rate){
                            e.charge_rate = parseInt(e.charge_rate)
                        }
                    })
                }

                // Branch Details
                if (e.sp_branch.data.length != 0){
                    e.sp_branch.data.map( e => {
                        if (e.pc_equip == 'Y' || e.pc_equip == 'N'){
                            e.pc_equip = 0
                        } else if (typeof e.pc_equip == 'string'){
                            parseInt(e.pc_equip)
                        }
                    })
                }

            })
            this.$store.commit('finance/SET_IS_DETAILS', res.data.result[0])
            
            // SET VIEW ACCESS
            
            if (this.currUser.user_role == 'encoder' && (this.isDetails.finance_status == 'Finance Pending (Initial)' || this.isDetails.finance_status == 'Finance Pending (Final)')){
                this.$store.commit('finance/SET_VIEW', true)
            } else if (this.currUser.is_oh_approver == '1'){
                this.$store.commit('finance/SET_VIEW', true)
            } else {
                this.$store.commit('finance/SET_VIEW', false)
            }

            // Check Details

            this.errorMessage = []

            if (this.isDetails.dsa_code == null && this.isDetails.dsa_id == null){
                if (this.isDetails.sp_other_primary_details == null) {
                    this.errorMessage.push('Complete business information')
                }

                if (this.isDetails.sp_primary_business_address == null) {
                    this.errorMessage.push('Complete primary business address')
                }

                if (this.isDetails.sp_primary_contact_information == null) {
                    this.errorMessage.push('Complete primary contact information')
                }

                if (this.isDetails.sp_location.length == 0) {
                    this.errorMessage.push('Upload at least one MOA')
                }
            }

            if (this.errorMessage.length == 0){
                this.componentLoaded = true
            } else {
                this.snackbar = true
            }

        },
        formatDate (date) {
            return format(parseISO(date), 'yyyy-MM-dd')
        },
    },
    components:{
        PrpInformation: PrpInformation
    },
}
</script>
