<template>
    <div class="mx-9 my-5">
        <v-row>
            <v-tabs
                v-model="tabs"
                align-with-title
                class="d-flex justify-center align-center"
            >
                <v-tab href="#bank">
                    Default Bank Account
                </v-tab>
                <v-tab href="#email" v-if="!$route.query.onboarding">
                    Email Address
                </v-tab>
                <v-tab href="#audit">
                    View Audit Trail
                </v-tab>
                <v-tabs-slider color="primary"></v-tabs-slider>
            </v-tabs>
        </v-row>
        <div v-if="tabs == 'bank'" class="mx-1 my-16">
            <BankAccount/>
        </div>
        <div v-if="tabs == 'email' && !$route.query.onboarding" class="mx-1 my-16">
            <EmailAddress/>
        </div>
        <div v-if="tabs == 'audit'" class="mx-1 my-16">
            <AuditTrail/>
        </div>
        

        <div class="mt-5" v-if="!$route.query.onboarding">

            <v-row class="mx-1 mb-10">
                <h3>PRP Information</h3>
            </v-row>

            <v-row>
                <v-col :cols="6">
                    <v-text-field
                        v-model="isDetails.bp_idno"
                        label="BP ID Number"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="6">
                    <v-text-field
                        v-model="isDetails.sp_primary_details.account_name"
                        label="BP Name"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row v-if="isDetails.sp_other_primary_details != null">
                <v-col :cols="5">
                    <v-text-field
                        v-model="isDetails.sp_other_primary_details.business_type_of_sub_agents"
                        label="Business Type of Sub Agents"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row v-if="isDetails.sp_location.length != 0">
                <v-col :cols="3">
                    <v-file-input
                        label="Mother Contract"
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        disabled
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="isDetails.sp_location[0].mother_of_contract">
                    <v-icon
                        @click="retrieveUploaded(isDetails.sp_location[0].mother_of_contract)"
                        >
                        mdi-eye
                    </v-icon>
                </v-col>
                <v-col :cols="3">
                    <v-file-input
                        label="AVP MOA"
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        disabled
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="isDetails.sp_location[0].moa_avp">
                    <v-icon
                        @click="retrieveUploaded(isDetails.sp_location[0].moa_avp)"
                        >
                        mdi-eye
                    </v-icon>
                </v-col>
                <v-col :cols="3">
                    <v-file-input
                        label="NONEX MOA"
                        prepend-icon="mdi-paperclip"
                        dense
                        outlined
                        disabled
                        hide-details="auto"
                        >
                    </v-file-input>
                </v-col>
                <v-col :cols="1" class="mt-1" v-if="isDetails.sp_location[0].moa_nonex">
                    <v-icon
                        @click="retrieveUploaded(isDetails.sp_location[0].moa_nonex)"
                        >
                        mdi-eye
                    </v-icon>
                </v-col>
            </v-row>

            <v-row v-if="isDetails.sp_other_primary_details != null">
                <v-col :cols="4">
                    <h4>Head Office Address</h4>
                </v-col>
                <v-col :cols="2">
                    <v-checkbox
                        v-model="isDetails.sp_other_primary_details.dpa_consent_tag"
                        true-value="Y"
                        false-value="N"
                        label="DPA Consent"
                        :disabled="denyView"
                        >
                    </v-checkbox>
                </v-col>
                <v-col :cols="2">
                    <v-checkbox
                        v-model="isDetails.sp_other_primary_details.pep_tag"
                        true-value="Y"
                        false-value="N"
                        label="PEP"
                        :disabled="denyView"
                        >
                    </v-checkbox>
                </v-col>
                <v-col :cols="2">
                    <v-checkbox
                        v-model="isDetails.sp_other_primary_details.vat_reg"
                        true-value="1"
                        false-value="0"
                        label="Vat Registered"
                        :disabled="denyView"
                        >
                    </v-checkbox>
                </v-col>
                <v-col :cols="2">
                    <v-checkbox
                        v-model="isDetails.sp_other_primary_details.hold"
                        true-value="Y"
                        false-value="N"
                        label="Hold"
                        :disabled="denyView"
                        >
                    </v-checkbox>
                </v-col>
            </v-row>

            <v-row v-if="isDetails.sp_primary_business_address != null">
                <v-col :cols="2">
                    <v-text-field
                        v-model="isDetails.sp_primary_business_address.address_line_1"
                        label="Address 1"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        v-model="isDetails.sp_primary_business_address.address_line_2"
                        label="Address 2"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        v-model="isDetails.sp_primary_business_address.city"
                        label="City"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        v-model="isDetails.sp_primary_business_address.province"
                        label="Province"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        v-model="isDetails.sp_primary_business_address.postal_code"
                        label="Zip Code"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        v-model="isDetails.sp_primary_business_address.country"
                        label="Country"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col :cols="12">
                    <h4>Contact Information</h4>
                </v-col>
            </v-row>

            <v-row v-if="isDetails.sp_primary_contact_information != null">
                <v-col :cols="3">
                    <v-text-field
                        v-model="isDetails.sp_primary_contact_information.address_line_1"
                        label="Landline 1"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="3">
                    <v-text-field
                        v-model="isDetails.sp_primary_contact_information.address_line_2"
                        label="Landline 2"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        v-model="isDetails.sp_primary_contact_information.cellphone"
                        label="Mobile No."
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
                <v-col :cols="2">
                    <v-text-field
                        v-model="isDetails.sp_primary_contact_information.landline"
                        label="Contact"
                        hide-details="auto"
                        outlined
                        dense
                        readonly
                        >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row v-if="!denyView">
                <v-col>
                    <v-btn
                        class="mx-3 mb-10"
                        color="primary"
                        style="float:right;"
                        @click="updateInformation"
                        >
                        <b>Update Information</b>
                    </v-btn>
                </v-col>
            </v-row>


            <v-row class="mt-10">
                <v-tabs
                    fixed-tabs
                    v-model="tabs2"
                >
                    <v-tab href="#branch">
                        Location / Branches
                    </v-tab>
                    <v-tab href="#commission">
                        Commission
                    </v-tab>
                    <v-tab href="#charges">
                        Charges
                    </v-tab>
                    <v-tabs-slider color="primary"></v-tabs-slider>
                </v-tabs>
            </v-row>
            <div v-if="tabs2 == 'branch'">
                <Branch/>
            </div>
            <div v-if="tabs2 == 'commission'">
                <Commission/>
            </div>
            <div v-if="tabs2 == 'charges'">
                <Charges/>
            </div>

        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import  BankAccount from './BankAccount.vue'
import  EmailAddress from './EmailAddress.vue'
import  AuditTrail from './AuditTrail.vue'
import  Branch from './Branch.vue'
import  Commission from './Commission.vue'
import  Charges from './Charges.vue'
import { parseISO, format } from 'date-fns'

export default {
    computed: {
        ...mapGetters({
            currUser: 'auth/currUser',
            isDetails: 'finance/isDetails',
            disable: 'finance/disable',
        })
    },
    data: () => ({
        tabs: null,
        tabs2: null,
        denyView: false
    }),
    components:{
        BankAccount: BankAccount,
        EmailAddress: EmailAddress,
        AuditTrail: AuditTrail,
        Branch: Branch,
        Commission: Commission,
        Charges: Charges
    },
    created (){
        if (this.currUser.user_role === 'encoder' || this.currUser.is_oh_approver == '1'){
            this.denyView = true
        } else if (this.currUser.is_onboarding_committee == '1' && this.isDetails.finance_status == 'Finance Pending (Final)'){
            this.denyView = true
        } else {
            this.denyView = false
        }
    }, 
    methods: {
        async updateInformation(){

            const initialPayload = {
				pep_tag: this.isDetails.sp_other_primary_details.pep_tag,
				hold: this.isDetails.sp_other_primary_details.hold,
				vat_reg: this.isDetails.sp_other_primary_details.vat_reg,
				dpa_consent_tag: this.isDetails.sp_other_primary_details.dpa_consent_tag,
			}

            const payload = {id: this.$route.params.id, payload: initialPayload}

            const res = await this.$store.dispatch('salesProspecting/doUpdateBusinessInformation', {payload})
			if(res.status === 200) {
				this.assignData()
			}
        },
        async assignData(){
            const res = await this.$store.dispatch('finance/doGetIsDetails', this.$route.params.id)

            res.data.result.map( e => {

                if (e.sp_other_primary_details){
                    if (e.sp_other_primary_details.business_type_of_sub_agents){
                        e.sp_other_primary_details.business_type_of_sub_agents = JSON.parse(e.sp_other_primary_details.business_type_of_sub_agents)
                    }
                }

                if (e.sp_location.length != 0){
                    e.sp_location[0].mother_of_contract = JSON.parse(e.sp_location[0].mother_of_contract)
                    e.sp_location[0].moa_nonex = JSON.parse(e.sp_location[0].moa_nonex)
                    e.sp_location[0].moa_avp = JSON.parse(e.sp_location[0].moa_avp)
                }
                
                e.bank_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.commission_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                e.charges_audit_trail.map( v => {
                    if (v.new_value){
                        v.new_value = JSON.parse(v.new_value)
                    }

                    if (v.prev_value){
                        v.prev_value = JSON.parse(v.prev_value)
                    }
                })

                // Commission Details
                if (e.is_commission.length != 0){
                    
                    e.is_commission.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })

                    let latestDate = e.is_commission.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });

                    e.is_commission = [latestDate]
                }

                // Charges Details

                if (e.is_charges.length != 0){
                    let newArr = []
                    e.is_charges.map( e=> {
                        if (e.tran_type == 'COM'){
                            newArr.push(e)
                        }
                    })
                    newArr.map( e=> {
                        e.created_at = this.formatDate(e.created_at)
                    })
                    let latestDate = newArr.reduce((latest, current) => {
                        if (!latest || current.created_at > latest.created_at) {
                            return current;
                        } else {
                            return latest;
                        }
                    });
                    e.is_charges = [latestDate]
                }


                if (e.is_def_bank_accounts){
                    e.is_def_bank_accounts.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_commission){
                    e.is_commission.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }
                    })
                }

                if (e.is_charges){
                    e.is_charges.map( e => {
                        if (e.id){
                            e.type = 'update'
                        } else {
                            e.type = null
                        }

                        if (e.charge_rate){
                            e.charge_rate = parseInt(e.charge_rate)
                        }
                    })
                }

                // Branch Details
                if (e.sp_branch.data.length != 0){
                    e.sp_branch.data.map( e => {
                        if (e.pc_equip == 'Y' || e.pc_equip == 'N'){
                            e.pc_equip = 0
                        } else if (typeof e.pc_equip == 'string'){
                            parseInt(e.pc_equip)
                        }
                    })
                }

            })
            this.$store.commit('finance/SET_IS_DETAILS', res.data.result[0])
        },
        formatDate (date) {
            return format(parseISO(date), 'yyyy-MM-dd')
        },
        async retrieveUploaded (item) {
            var max = item.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
            // console.log(max)
			const payload = {
				file: max.url
			}
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			// console.log(res)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		}
    }


    
    
}
</script>
