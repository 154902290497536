<template>
    <div class="my-16">
        <v-row v-if="isDetails.is_charges.length != 0">
            <v-col :cols="2">
                <h4>Status</h4>
            </v-col>
            <v-col :cols="2">
                <h4>Created At</h4>
            </v-col>
            <!-- <v-col :cols="2">
                <h4>Charge Amount</h4>
            </v-col> -->
            <v-col :cols="2">
                <h4>Charge Rate</h4>
            </v-col>
            <v-col :cols="3">
                <h4>Transaction Type</h4>
            </v-col>
        </v-row>
        <v-row v-for="(item, index) in isDetails.is_charges" :key="index">
            <v-col :cols="2">
                <v-text-field
                    :value="checkType(item.type)"
                    label="Status"
                    hide-details="auto"
                    outlined
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-text-field
                    v-model="item.created_at"
                    label="Created At"
                    hide-details="auto"
                    outlined
                    readonly
                    dense
                    >
                </v-text-field>
            </v-col>

            <!-- <v-col :cols="2">
                <v-text-field
                    v-model="item.charge_amount"
                    label="Charge Amount"
                    hide-details="auto"
                    outlined
                    dense
                    type="number"
                    hide-spin-buttons
                    :readonly="denyView"
                    >
                </v-text-field>
            </v-col> -->
            <!-- <v-col :cols="2">
                <v-text-field
                    v-model="item.charge_rate"
                    label="Charge Rate"
                    hide-details="auto"
                    outlined
                    dense
                    type="number"
                    hide-spin-buttons
                    :readonly="denyView"
                    >
                </v-text-field>
            </v-col> -->

            <v-col cols="2">
				<v-autocomplete
					label="Charge Rate"
					hide-details="auto"
					v-model="item.charge_rate"
					outlined
					dense
					:items="chargeValue"
                    :readonly="denyView"
					>
				</v-autocomplete>
			</v-col>

            <v-col :cols="3">
                <v-text-field
                    v-model="item.tran_type"
                    label="Transaction Type"
                    hide-details="auto"
                    outlined
                    dense
                    readonly
                    >
                </v-text-field>
            </v-col>
            <v-col :cols="1" v-if="currUser.user_role === 'encoder' && !denyView">
                <v-btn
                    text
                    color="error"
                    @click="removeCharges(item, index)"
                    >
                    <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col v-if="!disable && isDetails.is_charges.length == 0">
                <v-btn
                    class="mx-5"
                    color="primary"
                    style="float:right;"
                    @click="dialog = true"
                    >
                    <b>Add Charges +</b>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" persistent max-width="800">
            <v-card class="pt-5">
                <v-card-text class="my-5">
                     <span class="text-h5" id="dialog_title">Add Charges</span>
                </v-card-text>
                                
                <v-row class="mx-5 mb-3">
                    <!-- <v-col :cols="4">
                        <v-text-field
                            v-model="newCharges.charge_rate"
                            label="Charge Rate"
                            hide-details="auto"
                            outlined
                            type="number"
                            hide-spin-buttons
                            dense
                            >
                        </v-text-field>
                    </v-col> -->
                    <v-col cols="4">
                        <v-autocomplete
                            label="Charge Rate"
                            hide-details="auto"
                            v-model="newCharges.charge_rate"
                            outlined
                            dense
                            :items="chargeValue"
                            >
                        </v-autocomplete>
                    </v-col>
                    <!-- <v-col :cols="4">
                        <v-text-field
                            v-model="newCharges.charge_amount"
                            label="Charge Amount"
                            hide-details="auto"
                            outlined
                            type="number"
                            hide-spin-buttons
                            dense
                            >
                        </v-text-field>
                    </v-col> -->
                </v-row>

                <v-card-actions class="pb-5 py-5">
                    <v-btn
                        class="mx-3"
                        color="primary"
                        style="float:right;"
                        outlined
                        @click="dialog = false, clearNewCharges()"
                        >
                        <b>Cancel</b>
                    </v-btn>
                        <v-spacer></v-spacer>
                    <v-btn
                        v-if="newCharges.charge_rate"
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        @click="addCharges(), dialog = false"
                        >
                        <b>Add</b>
                    </v-btn>
                    <v-btn
                        v-else
                        class="mx-3"
                        color="success"
                        style="float:right;"
                        disabled
                        >
                        <b>Add</b>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default{
    computed: {
        ...mapGetters({
            currUser: 'auth/currUser',
            isDetails: 'finance/isDetails',
            disable: 'finance/disable'
        })
    },
    data: () => ({
        dialog: false,
        denyView: false,
        chargeValue: [2,5,10,15],
        newCharges: {
            id : null,
            bp_idno : '',
            type: 'create',
            charge_rate : '',
            tran_type: 'COM'
        },
    }),
    created (){
        if (this.currUser.user_role === 'encoder' && this.isDetails.finance_status == 'New'){
            this.denyView = false
        } else {
            this.denyView = true
        }
    },  
    methods: {
        checkType(type){
            if (type == 'update') {
                return 'Existing'
            } else if (type == 'create'){
                return 'New'
            } else if (type == 'delete'){
                return 'Delete'
            }
        },
        addCharges(){
            this.newCharges.bp_idno =  this.isDetails.bp_idno,
            this.isDetails.is_charges.push(this.newCharges)
            this.clearNewCharges()
        },
        clearNewCharges(){
            this.newCharges = {
                id : null,
                bp_idno : '',
                type: 'create',
                charge_rate : '',
                tran_type: 'COM'
            }
        },
        async removeCharges(e , index){
            if (e.id == null){
                this.isDetails.is_charges.splice(index, 1)
            } else if (this.isDetails.is_charges[index].type == 'delete'){
                this.isDetails.is_charges[index].type = 'update'
            } else {
                this.isDetails.is_charges[index].type = 'delete'
            }
        },
    }
}
</script>
